import { Component, OnInit } from '@angular/core';

// Ένα interface IRegStats, με μεταβλητές τα πεδία που έχει ο πίνακας στη βάση.
interface IRegStats {
  name?: string;
  studies_level?: number;
  inProgress?: number;
  completed_succeed?: number;
  completed_failed?: number;
  closed?: number;
  unknown?: number;
}

@Component({
  selector: 'app-course-depts-regs',
  templateUrl: './course-depts-regs.component.html',
  styleUrls: ['./course-depts-regs.component.scss']
})

export class CourseDeptsRegsComponent implements OnInit {

  period = "1"; /* Μια μεταβλητή που μπορεί να έχει τις τιμές 1(χειρμερινού εξαμήνου), 2(εαρινού εξαμήνου) και αντιστοιχούν σε ένα από τα δύο εξάμηνα δηλώσεων μαθηματων. 
  ** To improve: να φτιαχτεί μια συνάρτηση που να ελένχει ποιο εξάμηνο διανύουμε αυτή τη στιγμή, ώστε να εμφανίζει στο χρήστη ως default το αντίστοιχο εξάμηνο. */

  public year: number;
  public years: number[] = [];
  public lineChartLegend = true;

  constructor() {
    const newAcademicDate = new Date(2000, 9, 1);
    const startSpringPeriodDate = new Date(2000, 2, 1);
    const endSpringPeriodDate = new Date(2000, 8, 1);
    const today = new Date();
    if (today.getMonth()+1 >= newAcademicDate.getMonth()) {
      this.year = new Date().getFullYear()+1;
    }else{
      this.year = new Date().getFullYear();
    }

    if(startSpringPeriodDate.getMonth() <= today.getMonth() + 1 && today.getMonth() + 1 <= endSpringPeriodDate.getMonth()){
      this.period="2"
    }

    for (let year = this.year; year >= 2011; year--) {
      this.years.push(year);
    }
  }

  // Ορίζουμε μια array μεταβλητή regStats τύπου RegStats, όπου θα περάσουμε μέσα 
  regStats: IRegStats[] = [];

  /* Η συνάρτηση changeYear(year) καλείται κάθε φορά που αλλάζει τιμή το select element από το grade-stats-per-day.component.html, και παίρνει την τιμή που επιλέχτηκε και την
 καλεί την getRegStatsPerDay(this.period, year); */
  changeYear(year: number) {
    this.getCourseDeptsRegs(this.period, year);
  }

  /* Η συνάρτηση prevYear() καλείται κάθε φορά που κάνουμε click το button element της κλάσης fa-chevron-left από το grade-stats-per-day.component.html, και καλεί 
  την changeYear(year) αφού πρώτα ελένξει αν μπορεί να επιλεχτεί η προηγούμενη τιμή του select πεδίου */
  prevYear() {
    if (this.year > this.years[this.years.length - 1]) {
      this.year--;
      this.changeYear(this.year);
    }
  }

  /* Η συνάρτηση nextYear() καλείται κάθε φορά που κάνουμε click το button element της κλάσης fa-chevron-right από το grade-stats-per-day.component.html, και καλεί 
  την changeYear(year) αφού πρώτα ελένξει αν μπορεί να επιλεχτεί η επόμενη τιμή του select πεδίου */
  nextYear() {
    if (this.year < this.years[0]) {
      this.year++;
      this.changeYear(this.year);
    }
  }

  // Η συνάρτηση changePeriod(period) καλείται κάθε φορά που επιλέγουμε διαφορετικό radioButton
  changePeriod(period: string) {
    this.getCourseDeptsRegs(period, this.year);
  }


  ngOnInit(): void {
    this.getCourseDeptsRegs(this.period, this.year);
  }

  //Συνάρτηση που φορτώνει τα αποτελέσματα JSON από το link https://ws-ext.it.auth.gr/open/getRegStats/' + schoolYear + '/' + period στην array μεταβλητή regStats
  async getCourseDeptsRegs(period: string, year: number) {
    this.regStats.length = 0;
    let schoolYear = year - 1;
    let url = 'https://ws-ext.it.auth.gr/open/getRegStats/' + schoolYear + '/' + period;
    let jsonResults = await (await fetch(url)).json();
    if (!Array.isArray(jsonResults && jsonResults.regstats)) { return Promise.resolve() };
    this.regStats = jsonResults.regstats;
  }
}