<div class="ui_content" id="ui_content_specific">
    <div class="ui_content__active_area">
        <div class="tab-content" id="myTabContent0"><div style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            flex-direction: row;
            align-content: center;
        ">
            <button (click)="prevDay()" class="fa-solid fa-chevron-left selectButton"></button>
            <label id="daySelected" class="selectButton"></label>
            <button (click)="nextDay()" class="fa-solid fa-chevron-right selectButton"></button></div>
            <div class="tab-pane fade show active" id="chartTab" role="tabpanel" aria-labelledby="chart-tab0">
                <canvas baseChart [data]="regsStatsPerDayData" [options]="lineChartOptions" [legend]="lineChartLegend" [type]="'line'" min-height="500" max-height="500" style="width: 100vw; height: 100vh;">
                </canvas>
            </div>
        </div>
    </div>
</div>