import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, ChartOptions, ChartType } from "chart.js";

@Component({
  selector: 'app-grade-stats-per-day',
  templateUrl: './grade-stats-per-day.component.html',
  styleUrls: ['./grade-stats-per-day.component.scss']
})

export class GradeStatsPerDayComponent implements OnInit {

  onDate: string[] = [];
  instructors: number[] = [];
  gradebooks: number[] = [];

  period = "140000004"; /* Μια μεταβλητή που μπορεί να έχει τις τιμές 140000000(εξεταστική Ιανουαρίου), 140000002(εξεταστική Ιουνίου) και 140000004(εξεταστική Σεπτεμβρίου) και 
  αντιστοιχούν σε μία από τις κάθε εξεταστικές περιόδους.  ** To improve: να φτιαχτεί μια συνάρτηση που να ελένχει ποια περίοδο διανύουμε αυτή τη στιγμή, ώστε να εμφανίζει 
  στο χρήστη ως default την αντίστοιχη περιοδο. */

  // Μεταβλητή title τύπου string για τον τίτλο της γραφικής
  title: string = "";
  subTitle: string = "";

  // Μεταβλητή lineChartLabels τύπου array string, όπου θα έχει μέσα τα labels του άξονα x της γραφικής 
  lineChartLabels: string[] = this.onDate;

  // Μεταβλητή gradesPerDayData τύπου ChartData και είδος line όπου φορτώνουμε τα δεδομένα της γραφικής
  gradesPerDayData: ChartData<'line'> = {
    datasets: []
  };

  /* Μεταβλητή lineChartOptions τύπου ChartOptions και είδος line όπου φορτώνουμε διάφορες επιλογές για 
  τη γραφική, οπως ο τίτλος, ο υποτιτλος, ο τίτλος του άξονα x, ο τίτλος του άξονα y και άλλα της γραφικής */
  public lineChartOptions: ChartOptions<'line'> = {};
  public year: number;
  public years: number[] = [];
  public lineChartLegend = true;

  constructor() {
    const newAcademicDate = new Date(2000, 11, 1);
    const today = new Date();
    if (today.getMonth() + 1 >= newAcademicDate.getMonth()) {
      this.year = new Date().getFullYear() + 1;
    } else {
      this.year = new Date().getFullYear();
    }

    for (let year = this.year; year >= 2011; year--) {
      this.years.push(year);
    }
  }

  /* Η συνάρτηση changeYear(year) καλείται κάθε φορά που αλλάζει τιμή το select element από το grade-stats-per-day.component.html, και παίρνει την τιμή που επιλέχτηκε και την
   καλεί την getGradeStatsPerDay(this.period, year); */
  changeYear(year: number) {
    this.getGradeStatsPerDay(this.period, year);
  }

  /* Η συνάρτηση prevYear() καλείται κάθε φορά που κάνουμε click το button element της κλάσης fa-chevron-left από το grade-stats-per-day.component.html, και καλεί 
  την changeYear(year) αφού πρώτα ελένξει αν μπορεί να επιλεχτεί η προηγούμενη τιμή του select πεδίου */
  prevYear() {
    if (this.year > this.years[this.years.length - 1]) {
      this.year--;
      this.changeYear(this.year);
    }
  }

  /* Η συνάρτηση nextYear() καλείται κάθε φορά που κάνουμε click το button element της κλάσης fa-chevron-right από το grade-stats-per-day.component.html, και καλεί 
  την changeYear(year) αφού πρώτα ελένξει αν μπορεί να επιλεχτεί η επόμενη τιμή του select πεδίου */
  nextYear() {
    if (this.year < this.years[0]) {
      this.year++;
      this.changeYear(this.year);
    }
  }

  // Η συνάρτηση changePeriod(period) καλείται κάθε φορά που επιλέγουμε διαφορετικό radioButton
  changePeriod(period: string) {
    this.getGradeStatsPerDay(period, this.year);
  }

  ngOnInit(): void {
    this.getGradeStatsPerDay(this.period, this.year);
  }

  /* Συνάρτηση που φορτώνει τα αποτελέσματα JSON από το link https://ws-ext.it.auth.gr/open/getGradeStatsPerDay/' + schoolYear + '/' + period, στις μεταβλητές onDate, instructors, gradebooks, ενώ αντίστοιχα 
  τα φορτώνει και στη γραφική */
  async getGradeStatsPerDay(period: string, year: number) {

    this.onDate.length = 0;
    this.instructors.length = 0;
    this.gradebooks.length = 0;
    this.lineChartLabels.length = 0;
    let schoolYear = year - 1;
    let url = 'https://ws-ext.it.auth.gr/open/getGradeStatsPerDay/' + schoolYear + '/' + period;
    let jsonResults = await (await fetch(url)).json();
    if (!Array.isArray(jsonResults && jsonResults.gradestats)) { return Promise.resolve() };

    // Φορτωνουμε τα αποτελέσματα JSON στις τοπικές μεταβλητές onDate, instructors και gradebooks
    jsonResults.gradestats.forEach((result: { onDate: string; Instructors: number; Gradebooks: number; }) => {
      this.onDate.push(result.onDate);
      this.instructors.push(result.Instructors);
      this.gradebooks.push(result.Gradebooks);
    });

    this.lineChartLabels = this.onDate; // Ορίζουμε τις νέες ημερομηνίες για τον άξονα x της γραφικής

    // Φορτώνουμε τα νέα δεδομένα στη γραφική από τις μεταβλητές instructors, gradebooks και lineChartLabels που φορτωθηκαν παραπάνω με τα νέα δεδομένα
    this.gradesPerDayData = {
      labels: this.lineChartLabels,// ημερομηνίες για τον άξονα x της γραφικής
      datasets: [
        {
          data: this.gradebooks,
          label: 'Βαθμολόγιες',
          //tension: 0.5, αλλάζοντας αυτή τη τιμή μπορούμε να έχουμε ομαλότερες καμπύλες στην γραφική
          backgroundColor: 'rgba(255,0,0,0.3)'
        },
        {
          data: this.instructors,
          label: 'Διδάσκοντες',
          //tension: 0.5, αλλάζοντας αυτή τη τιμή μπορούμε να έχουμε ομαλότερες καμπύλες στην γραφική
          backgroundColor: 'rgba(0,0,255,0.3)'
        }
      ]
    };

    if (period == "140000000") {
      this.subTitle = "ΕΞΕΤΑΣΤΙΚΗ ΙΑΝΟΥΑΡΙΟΥ";
    } else if (period == "140000002") {
      this.subTitle = "ΕΞΕΤΑΣΤΙΚΗ ΙΟΥΝΙΟΥ";
    } else {
      this.subTitle = "ΕΞΕΤΑΣΤΙΚΗ ΣΕΠΤΕΜΒΡΙΟΥ";
    }

    this.title = 'Ημερήσια Εξέλιξη Κατάθεσης Βαθμολογίων ' + schoolYear + " - " + year; // Ορίζουμε τον νέο τίτλο της γραφικής με βάση τη χρονιά 
    // Φορτώνουμε το νέο τίτλο στη γραφική
    this.lineChartOptions = {
      scales: {
        y: {
          ticks: {
            precision: 0
          }
        }
      },
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: this.title,
          font: {
            size: 20
          }
        },
        subtitle: {
          display: true,
          text: this.subTitle
        }
      }
    };
  }
}