import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ChartConfiguration, ChartData, ChartOptions, ChartType } from "chart.js";
import { Router } from '@angular/router';
import { DateTime } from 'luxon';


@Component({
  selector: 'app-course-depts-regs-per-single-students',
  templateUrl: './course-depts-regs-per-single-students.component.html',
  styleUrls: ['./course-depts-regs-per-single-students.component.scss']
})
export class CourseDeptsRegsPerSingleStudentsComponent implements OnInit, OnChanges {

  hoursData: number[][] = [];
  datasetsB: any[] = []
  departments: string[] = [];

  //dataRLoaded = false // Είναι μια μεταβλητή ελέγχου ώστε να ελένξουμε αν έχουν φορτωθεί όλα τα δεδομένα πριν φορτώσουμε τη γραφική 
  public lineChartLegend = true;

  constructor(private _route: ActivatedRoute, private _router: Router) {
  }

  // φορτώνουμε την ημερομηνία date από το url που κάλεσε το component CourseDeptRegsPerDayComponent
  date = this._route.snapshot.params.date;

  // Μεταβλητή title τύπου string για τον τίτλο της γραφικής
  title: string = "";

  // Μεταβλητή lineChartLabels τύπου array string, όπου θα έχει μέσα τα labels του άξονα x της γραφικής 
  public lineChartLabels: string[] = ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", "13:00",
    "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];

  // Μεταβλητή regsStatsPerDayData τύπου ChartData και είδος line όπου φορτώνουμε τα δεδομένα της γραφικής
  regsStatsPerDayData: ChartData<'line'> = {
    datasets: [], labels: []
  };

  /* Μεταβλητή lineChartOptions τύπου ChartOptions και είδος line όπου φορτώνουμε διάφορες επιλογές για 
  τη γραφική, οπως ο τίτλος, ο υποτιτλος, ο τίτλος του άξονα x, ο τίτλος του άξονα y και άλλα της γραφικής */
  public lineChartOptions: ChartOptions<'line'> = {};

  ngOnInit(): void {
    this.getCourseDeptsRegsPerSingleStudents(this.date);
  }

  ngOnChanges(): void {
    this.regsStatsPerDayData = {
      datasets: [], labels: []
    };

    this.getCourseDeptsRegsPerSingleStudents(this.date);
  }

  public prevDay(): void {
    this.date = DateTime.fromISO(this.date).minus({ days: 1 }).toFormat('yyyy-MM-dd');
    //console.log(this.date);
    this.ngOnChanges();
    this._router.navigate(["/course-depts-regs-per-single-students/" + this.date]);
  }

  public nextDay(): void {
    var next_day = DateTime.fromISO(this.date).plus({ days: 1 }).toFormat('yyyy-MM-dd');
    //console.log(this.date);
    // κάνουμε έναν έλεγχο ώστε να μην μπορεί να δείξει ημερομηνίες που είναι μελλοντικές.
    if (next_day <= DateTime.now().toFormat('yyyy-MM-dd')) {
      this.date = next_day;
      this.ngOnChanges();
      this._router.navigate(["/course-depts-regs-per-single-students/" + this.date]);
    }
  }

  /* Συνάρτηση που φορτώνει τα αποτελέσματα JSON από το link https://ws-ext.it.auth.gr/open/getCourseRegsStudents/' + date στις μεταβλητές onDate, students, registrations, ενώ αντίστοιχα 
  τα φορτώνει και στη γραφική */
  async getCourseDeptsRegsPerSingleStudents(date: string) {

    (document.getElementById("daySelected") as HTMLElement).textContent = date;
    this.departments = [];
    let url = 'https://ws-ext.it.auth.gr/open/getCourseRegsStudents/' + date;
    let jsonResults = await (await fetch(url)).json();
    if (!Array.isArray(jsonResults && jsonResults.regsstats)) { return Promise.resolve() };
    // Φορτωνουμε τα αποτελέσματα JSON στις τοπικές μεταβλητές onDate, students και registrations

    this.datasetsB = [];
    var countDept = 0;
    jsonResults.regsstats.forEach((result: any) => {
      //if (result.department != null) {
      this.departments.push(result.department);
      this.hoursData[countDept] = [];
      var k = 0;
      while (k <= 23) {
        this.hoursData[countDept].push(result[k]);
        k++;
      }
      countDept++;
      //}
    });

    var newDataset;
    //console.log(countDept);
    var i = countDept - 1; // 
    while (i >= 0) {
      //console.log(this.departments[i] + " " + this.hoursData[i]);
      var selectColour = "#" + Math.floor(Math.random() * 16777215).toString(16);
      newDataset = {
        label: this.departments[i],
        data: this.hoursData[i],
        backgroundColor: selectColour,
        borderColor: selectColour,
        pointBackgroundColor: selectColour,
        pointBorderColor: selectColour
      }
      this.datasetsB.push(newDataset);
      i--;
    }

    this.regsStatsPerDayData = {
      labels: this.lineChartLabels,
      datasets: this.datasetsB,
    }

    this.title = 'Εξέλιξη Δηλώσεων Μαθημάτων με μοναδικούς Φοιτητές ανά Ώρα για ' + date; // Ορίζουμε τον νέο τίτλο της γραφικής με βάση την ημερομηνία
    // Φορτώνουμε το νέο τίτλο στη γραφική
    this.lineChartOptions = {
      scales: {
        y: {
          ticks: {
            precision: 0
          }
        }
      },
      maintainAspectRatio: false,
      plugins: {
        legend: {
          onClick: function (e, legendItem) {
            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var alreadyHidden = ci.getDatasetMeta(index).hidden === null ? false : ci.getDatasetMeta(index).hidden;
            ci.data.datasets.forEach(function (e, i) {
              var meta = ci.getDatasetMeta(i);
              if (i !== index) {
                if (!alreadyHidden) {
                  meta.hidden = meta.hidden === null ? !meta.hidden : null;
                }
                else if (meta.hidden === null) {
                  meta.hidden = true;
                }
              }
              else if (i === index) {
                meta.hidden = null;
              }
            });
            ci.update();
          }
        },
        title: {
          display: true,
          text: this.title,
          font: {
            size: 20
          }
        }
      }
    };
  }
}