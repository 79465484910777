import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, ChartOptions, ChartType } from "chart.js";
import { Router } from '@angular/router';
import { DateTime } from 'luxon';


@Component({
  selector: 'app-course-regs-per-day',
  templateUrl: './course-regs-per-day.component.html',
  styleUrls: ['./course-regs-per-day.component.scss']
})
export class CourseRegsPerDayComponent implements OnInit {

  onDate: string[] = [];
  students: number[] = [];
  registrations: number[] = [];

  period = "1"; /* Μια μεταβλητή που μπορεί να έχει τις τιμές 1(χειμερινού εξαμήνου), 2(εαρινού εξαμήνου) και αντιστοιχούν σε ένα από τα δύο εξάμηνα δηλώσεων μαθηματων. */

  public year: number;
  public years: number[] = [];
  dataRLoaded = false // Είναι μια μεταβλητή ελέγχου ώστε να ελένξουμε αν έχουν φορτωθεί όλα τα δεδομένα πριν φορτώσουμε τη γραφική 
  public lineChartLegend = true;

  constructor(private router: Router) {
    const newAcademicDate = new Date(2000, 9, 1);
    const startSpringPeriodDate = new Date(2000, 2, 1);
    const endSpringPeriodDate = new Date(2000, 8, 1);
    const today = new Date();
    if (today.getMonth() + 1 >= newAcademicDate.getMonth()) {
      this.year = new Date().getFullYear() + 1
    } else {
      this.year = new Date().getFullYear();
    }

    if(startSpringPeriodDate.getMonth() <= today.getMonth() + 1 && today.getMonth() + 1 <= endSpringPeriodDate.getMonth()){
      this.period="2"
    }
    
    for (let year = this.year; year >= 2011; year--) {
      this.years.push(year);
    }
  }

  // Μεταβλητή title τύπου string για τον τίτλο της γραφικής
  title: string = "";
  subTitle: string = "";

  // Μεταβλητή lineChartLabels τύπου array string, όπου θα έχει μέσα τα labels του άξονα x της γραφικής 
  public lineChartLabels: string[] = this.onDate;;

  // Μεταβλητή regsStatsPerDayData τύπου ChartData και είδος line όπου φορτώνουμε τα δεδομένα της γραφικής
  regsStatsPerDayData: ChartData<'line'> = {
    datasets: []
  };

  /* Μεταβλητή lineChartOptions τύπου ChartOptions και είδος line όπου φορτώνουμε διάφορες επιλογές για 
  τη γραφική, οπως ο τίτλος, ο υποτιτλος, ο τίτλος του άξονα x, ο τίτλος του άξονα y και άλλα της γραφικής */
  public lineChartOptions: ChartOptions<'line'> = {};


  /* Η συνάρτηση changeYear(year) καλείται κάθε φορά που αλλάζει τιμή το select element από το grade-stats-per-day.component.html, και παίρνει την τιμή που επιλέχτηκε και την
 καλεί την getRegStatsPerDay(this.period, year); */
  changeYear(year: number) {
    this.getRegsStatsPerDay(this.period, year);
  }

  /* Η συνάρτηση prevYear() καλείται κάθε φορά που κάνουμε click το button element της κλάσης fa-chevron-left από το grade-stats-per-day.component.html, και καλεί 
  την changeYear(year) αφού πρώτα ελένξει αν μπορεί να επιλεχτεί η προηγούμενη τιμή του select πεδίου */
  prevYear() {
    if (this.year > this.years[this.years.length - 1]) {
      this.year--;
      this.changeYear(this.year);
    }
  }

  /* Η συνάρτηση nextYear() καλείται κάθε φορά που κάνουμε click το button element της κλάσης fa-chevron-right από το grade-stats-per-day.component.html, και καλεί 
  την changeYear(year) αφού πρώτα ελένξει αν μπορεί να επιλεχτεί η επόμενη τιμή του select πεδίου */
  nextYear() {
    if (this.year < this.years[0]) {
      this.year++;
      this.changeYear(this.year);
    }
  }

  // Η συνάρτηση changePeriod(period) καλείται κάθε φορά που επιλέγουμε διαφορετικό radioButton
  changePeriod(period: string) {
    this.getRegsStatsPerDay(period, this.year);
  }

  ngOnInit(): void {
    this.getRegsStatsPerDay(this.period, this.year);
  }

  public chartClicked(e: any): void {
    var date = DateTime.fromFormat(e.event.chart.tooltip.dataPoints[0].label, "LLL  d yyyy").toFormat('yyyy-MM-dd');
    //console.log(date)
    if (date == "Invalid DateTime") {
      date = DateTime.fromFormat(e.event.chart.tooltip.dataPoints[0].label, "LLL dd yyyy").toFormat('yyyy-MM-dd');
    }
    //console.log(e.event.chart.tooltip.dataPoints[0].label)
    if (e.active[0]?.datasetIndex >= 0) {
      if (e.active[0]?.datasetIndex == 0) {
        //this.router.navigate(["/course-dept-regs-per-day/" + e.event.chart.tooltip.dataPoints[0].label]);
        this.router.navigate(["/course-depts-regs-per-single-students/" + date]);// "/course-depts-regs-per-single-students/2023-02-12"
      } else {
        //console.log(e.event.chart.tooltip.dataPoints[0].label) Feb 15 2022
        this.router.navigate(["/course-depts-regs-per-day/" + date]);// "/course-dept-regs-per-day/2023-02-12"
      }
    }
  }

  /* Συνάρτηση που φορτώνει τα αποτελέσματα JSON από το link https://ws-ext.it.auth.gr/open/getRegStatsPerDay/' + schoolYear + '/' + period στις μεταβλητές onDate, students, registrations, ενώ αντίστοιχα 
  τα φορτώνει και στη γραφική */
  async getRegsStatsPerDay(period: string, year: number) {
    this.dataRLoaded = false; // Είναι μια μεταβλητή ελέγχου ώστε να ελένξουμε αν έχουν φορτωθεί όλα τα δεδομένα πριν φορτώσουμε τη γραφική 

    this.onDate.length = 0;
    this.students.length = 0;
    this.registrations.length = 0;
    this.lineChartLabels.length = 0;
    let schoolYear = year - 1;
    let url = 'https://ws-ext.it.auth.gr/open/getRegStatsPerDay/' + schoolYear + '/' + period;
    let jsonResults = await (await fetch(url)).json();
    if (!Array.isArray(jsonResults && jsonResults.regstats)) { return Promise.resolve() };
    // Φορτωνουμε τα αποτελέσματα JSON στις τοπικές μεταβλητές onDate, students και registrations
    jsonResults.regstats.forEach((result: { onDate: string; Students: number; Registrations: number; }) => {
      this.onDate.push(result.onDate);
      this.students.push(result.Students);
      this.registrations.push(result.Registrations);
    });
    this.lineChartLabels = this.onDate; // Ορίζουμε τις νέες ημερομηνίες για τον άξονα x της γραφικής
    this.dataRLoaded = true; // Είναι μια μεταβλητή ελέγχου ώστε να ελένξουμε αν έχουν φορτωθεί όλα τα δεδομένα πριν φορτώσουμε τη γραφική 

    // Φορτώνουμε τα νέα δεδομένα στη γραφική από τις μεταβλητές students, registrations και lineChartLabels που φορτωθηκαν παραπάνω με τα νέα δεδομένα
    this.regsStatsPerDayData = {
      labels: this.lineChartLabels,// ημερομηνίες για τον άξονα x της γραφικής
      datasets: [
        {
          data: this.students,
          label: 'Φοιτητές',
          //tension: 0.5, αλλάζοντας αυτή τη τιμή μπορούμε να έχουμε ομαλότερες καμπύλες στην γραφική
          backgroundColor: 'rgba(255,0,0,0.3)'
        },
        {
          data: this.registrations,
          label: 'Δηλώσεις',
          //tension: 0.5, αλλάζοντας αυτή τη τιμή μπορούμε να έχουμε ομαλότερες καμπύλες στην γραφική
          backgroundColor: 'rgba(0,0,255,0.3)'
        }
      ]
    };

    if (period == "1") {
      this.subTitle = "ΧΕΙΜΕΡΙΝΟΥ ΕΞΑΜΗΝΟΥ";
    } else {
      this.subTitle = "ΕΑΡΙΝΟΥ ΕΞΑΜΗΝΟΥ";
    }

    this.title = 'Ημερήσια Εξέλιξη Δηλώσεων Μαθημάτων ' + schoolYear + " - " + year; // Ορίζουμε τον νέο τίτλο της γραφικής με βάση τη χρονιά 
    // Φορτώνουμε το νέο τίτλο στη γραφική
    this.lineChartOptions = {
      scales: {
        y: {
          ticks: {
            precision: 0
          }
        }
      },
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: this.title,
          font: {
            size: 20
          }
        },
        subtitle: {
          display: true,
          text: this.subTitle
        }
      }
    };
  }
}