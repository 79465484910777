import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FormsModule } from '@angular/forms';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgChartsModule } from 'ng2-charts';
import { GradeStatsPerDayComponent } from './grade-stats-per-day/grade-stats-per-day.component';
import { CourseRegsPerDayComponent } from './course-regs-per-day/course-regs-per-day.component';
import { CourseDeptsRegsComponent } from './course-depts-regs/course-depts-regs.component';
import { CourseDeptsRegsPerDayComponent } from './course-depts-regs-per-day/course-depts-regs-per-day.component';
import { CourseDeptsRegsPerSingleStudentsComponent } from './course-depts-regs-per-single-students/course-depts-regs-per-single-students.component';
import { CourseRegsPerDeptPerDayComponent } from './course-regs-per-dept-per-day/course-regs-per-dept-per-day.component';
import { NewComponentComponent } from './new-component/new-component.component';


const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: "full" },
  { path: 'grade-stats-per-day', component: GradeStatsPerDayComponent },
  { path: 'new-component', component: NewComponentComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'course-regs-per-day', component: CourseRegsPerDayComponent },
  { path: 'course-depts-regs', component: CourseDeptsRegsComponent },
  { path: 'course-depts-regs-per-day/:date', component: CourseDeptsRegsPerDayComponent },
  { path: 'course-depts-regs-per-single-students/:date', component: CourseDeptsRegsPerSingleStudentsComponent },
  { path: 'course-regs-per-dept-per-day/:dept/:date', component: CourseRegsPerDeptPerDayComponent },
  // { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    GradeStatsPerDayComponent,
    CourseRegsPerDayComponent,
    CourseDeptsRegsComponent,
    CourseDeptsRegsPerDayComponent,
    CourseDeptsRegsPerSingleStudentsComponent,
    CourseRegsPerDeptPerDayComponent,
    NewComponentComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    FormsModule,
    MdbDropdownModule,
    BrowserAnimationsModule,
    NgChartsModule,
    MdbTabsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
