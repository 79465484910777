<div class="ui_content__active_area">
    <div class="ui_title--primary ui_title--primary_specific">
        Υπηρεσίες Ηλεκτρονικής Γραμματείας ΑΠΘ
    </div>

    <div class="ui_banner">
        <div class="ui_banner__box ui_banner__box--title" id="ui_banner__box--title_specific">
            <img class="ui_banner__logo" id="ui_banner__logo_specific" src="../../assets/sis-128.png" alt="">
            <span class="ui_banner__text--servicename" id="ui_banner__text--servicename_specific">sis</span>
            <span class="ui_banner__text--auth" id="ui_banner__text--auth_specific">.auth </span>
            <span class="ui_banner__text--auth" id="ui_banner__text--servicetitle_specific">&nbsp;- Student Information
                System</span>
        </div>
    </div>
    <div *ngFor="let alert of alerts">
        <div *ngIf="alert.show" class="ui_box ui_box--warning alert-{{alert.style || info}} ui_text--size-S"
            style="justify-content: center;text-align: -webkit-center">
            <p>{{alert.message}}</p>
            <a *ngIf="alert.link" href="{{alert.link}}">{{alert.link}}</a>
        </div>
    </div>
    <div class="ui--clear_float"></div>
    <div class="ui_box">
        <div class="ui_inside_box">
            <div class="logins_ui_box">
                <a href="https://students.auth.gr">
                    <div id="banner_students_button_specific">
                        <span class="banner_students_button_text"
                            id="banner_students_button_text_specific">Φοιτητές</span>
                    </div>
                </a>
                <div class="logins_ui_descr">
                    Οι φοιτητές μπορούν να: <ul style="margin: 0px">
                        <li>υποβάλλουν δήλωση μαθημάτων κάθε εξάμηνο</li>
                        <li>βλέπουν τα μαθήματα του εξαμήνου τους και τη βαθμολογία τους</li>
                        <li>παρακολουθούν τις προϋποθέσεις λήψης πτυχίου και την κάλυψή τους</li>
                        <li>καταθέτουν αιτήσεις για την έκδοση πιστοποιητικών</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="ui_inside_box">
            <div class="logins_ui_box">
                <a href="https://faculty.auth.gr">
                    <div id="banner_instructors_button_specific">
                        <span class="banner_instructors_button_text"
                            id="banner_instructors_button_text_specific">Διδάσκοντες</span>

                    </div>
                </a>
                <div class="logins_ui_descr">
                    Οι διδάσκοντες μπορούν να: <ul style="margin: 0px">
                        <li>βλέπουν τα μαθήματα, τις τάξεις και τους φοιτητές τους στο τρέχον εξάμηνο και ιστορικά</li>
                        <li>υποβάλλουν τα βαθμολόγια των τάξεων τους με ηλεκτρονική υπογραφή</li>
                        <li>αναζητούν προφίλ φοιτητών τους στα μαθήματα που δίδαξαν οι ίδιοι</li>
                        <li>βλέπουν εργασίες που έχουν αναλάβει την επίβλεψή τους</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="ui_inside_box_last">
            <div class="logins_ui_box">
                <a href="https://registrar.auth.gr">
                    <div id="banner_registrar_button_specific">
                        <span class="banner_registrar_button_text"
                            id="banner_registrar_button_text_specific">Γραμματείες</span>
                    </div>
                </a>
                <div class="logins_ui_descr">
                    Οι υπάλληλοι γραμματείας τμήματος μπορούν να: <ul style="margin: 0px">
                        <li>οργανώνουν τα προγράμματα σπουδών του τμήματος</li>
                        <li>ανοίγουν δηλώσεις μαθημάτων για τους φοιτητές τους</li>
                        <li>δέχονται τις βαθμολογίες που καταθέτουν οι διδάσκοντες στις τάξεις τους</li>
                        <li>διεκπεραιώνουν τις αιτήσεις των φοιτητών για την έκδοση πιστοποιητικών</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="ui_box">
        <mdb-tabs [pills]="true" style="flex-wrap: nowrap">
            <mdb-tab title="ΠΡΟΠΤΥΧΙΑΚΑ">
                <div *ngIf='!regPeriodsUndergraduate' style="display:flex;justify-content: center;">
                    <br />Αυτή τη στιγμή κανένα προπτυχιακό
                    τμήμα δεν έχει ανοιχτή περίοδο δηλώσεων μαθημάτων.<br /><br />
                </div>
                <div *ngIf='regPeriodsUndergraduate'>
                    <div id="table_title">Τα παρακάτω τμήματα έχουν ορίσει περίοδο υποβολής δηλώσεων:<br /><br /></div>
                    <table class="ui_table responsive">
                        <thead>
                            <tr>
                                <th>Τμήμα</th>
                                <th>Από</th>
                                <th>Έως</th>
                                <th>Ακαδ.Έτος</th>
                                <th>Περίοδος</th>
                                <th>Γράφημα</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let regPeriod of regPeriodsUndergraduate">
                                <td [ngStyle]="{'background-color': getColor(regPeriod.start_date)}">{{
                                    regPeriod.name }}</td>
                                <td [ngStyle]="{'background-color': getColor(regPeriod.start_date)}">{{
                                    regPeriod.start_date }}</td>
                                <td [ngStyle]="{'background-color': getColor(regPeriod.start_date)}">{{
                                    regPeriod.end_date }}</td>
                                <td [ngStyle]="{'background-color': getColor(regPeriod.start_date)}">{{
                                    regPeriod.reg_year }}</td>
                                <td [ngStyle]="{'background-color': getColor(regPeriod.start_date)}">{{
                                    regPeriod.reg_term }}</td>
                                <td [ngStyle]="{'background-color': getColor(regPeriod.start_date)}"><button
                                        class="cButton" (click)="chartIconClick(regPeriod.depcatID)"><span
                                            class="fa-sharp fa-solid fa-chart-column chartColors"
                                            style="vertical-align: bottom"></span></button></td>
                            </tr>
                        <tbody>
                    </table>
                </div>
            </mdb-tab>

            <mdb-tab title="ΜΕΤΑΠΤΥΧΙΑΚΑ">
                <div *ngIf='!regPeriodsMaster' style="display:flex;justify-content: center;"><br />Αυτή τη
                    στιγμή κανένα μεταπτυχιακό δεν έχει ανοιχτή περίοδο δηλώσεων μαθημάτων.<br /><br /></div>
                <div *ngIf='regPeriodsMaster'>
                    <div id="table_title">Τα παρακάτω τμήματα έχουν ορίσει περίοδο υποβολής δηλώσεων
                        μεταπτυχιακών:<br /><br />
                    </div>
                    <table class="ui_table responsive">
                        <thead>
                            <tr>
                                <th>Τμήμα</th>
                                <th>Από</th>
                                <th>Έως</th>
                                <th>Ακαδ.Έτος</th>
                                <th>Περίοδος</th>
                                <th>Γράφημα</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let regPeriod of regPeriodsMaster">
                                <td [ngStyle]="{'background-color': getColor(regPeriod.start_date)}">{{
                                    regPeriod.name }}</td>
                                <td [ngStyle]="{'background-color': getColor(regPeriod.start_date)}">{{
                                    regPeriod.start_date }}</td>
                                <td [ngStyle]="{'background-color': getColor(regPeriod.start_date)}">{{
                                    regPeriod.end_date }}</td>
                                <td [ngStyle]="{'background-color': getColor(regPeriod.start_date)}">{{
                                    regPeriod.reg_year }}</td>
                                <td [ngStyle]="{'background-color': getColor(regPeriod.start_date)}">{{
                                    regPeriod.reg_term }}</td>
                                <td [ngStyle]="{'background-color': getColor(regPeriod.start_date)}"><button
                                        class="cButton" (click)="chartIconClick(regPeriod.depcatID)"><span
                                            class="fa-sharp fa-solid fa-chart-column chartColors"
                                            style="vertical-align: bottom"></span></button></td>
                            </tr>
                        <tbody>
                    </table>
                </div>
            </mdb-tab>
        </mdb-tabs>
    </div>
</div>