<div class="ui_content" id="ui_content_specific">
    <div class="ui_content__active_area"><div style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
    ">
        <button (click)="prevYear()" class="fa-solid fa-chevron-left selectButton"></button>
        <select [(ngModel)]="year" (ngModelChange)="changeYear($event)" class="yearSelector">
            <option *ngFor="let year of years" [value]="year">{{ year - 1 }} - {{ year }}</option>
        </select>
        <label id="schoolYear" style="display:none"></label>
        <button (click)="nextYear()" class="fa-solid fa-chevron-right selectButton"></button></div>
        <ul class="nav nav-tabs mb-3" id="myTab0" role="tablist">
            <li class="nav-item" role="presentation">
                <input mdbRadio class="btn-check" [checked]="true" type="radio" name="radio" [(ngModel)]="period"
                    [value]="140000000" (ngModelChange)="changePeriod($event)" id="140000000" />
                <label class="btn btn-secondary" for="140000000">ΕΞΕΤΑΣΤΙΚΗ<br>ΙΑΝΟΥΑΡΙΟΥ</label>
            </li>
            <li class="nav-item" role="presentation">
                <input mdbRadio class="btn-check" type="radio" name="radio" [(ngModel)]="period"
                    [value]="140000002" (ngModelChange)="changePeriod($event)" id="140000002"/>
                <label class="btn btn-secondary" for="140000002">ΕΞΕΤΑΣΤΙΚΗ<br>ΙΟΥΝΙΟΥ</label>
            </li>
            <li class="nav-item" role="presentation">
                <input mdbRadio class="btn-check" type="radio" name="radio" [(ngModel)]="period"
                    [value]="140000004" (ngModelChange)="changePeriod($event)" id="140000004"/>
                <label class="btn btn-secondary" for="140000004">ΕΞΕΤΑΣΤΙΚΗ<br>ΣΕΠΤΕΜΒΡΙΟΥ</label>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent0">
            <div class="tab-pane fade show active" id="chartTab" role="tabpanel" aria-labelledby="chart-tab0">
                <canvas baseChart min-height="500" max-height="500" style="width: 100vw; height: 100vh;" [type]="'line'" [data]="gradesPerDayData"
                    [options]="lineChartOptions" [legend]="lineChartLegend">
                </canvas>
            </div>
        </div>
    </div>
</div>