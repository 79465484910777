<div class="ui_content" id="ui_content_specific">
    <div class="ui_content__active_area"><div style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
    ">
        <button (click)="prevYear()" class="fa-solid fa-chevron-left selectButton"></button>
        <select [(ngModel)]="year" (ngModelChange)="changeYear($event)" class="yearSelector">
            <option *ngFor="let year of years" [value]="year">{{ year - 1 }} - {{ year }}</option>
        </select>
        <button (click)="nextYear()" class="fa-solid fa-chevron-right selectButton"></button></div>
        <ul class="nav nav-tabs mb-3" id="myTab0" role="tablist">
            <li class="nav-item" role="presentation">
                <input class="btn-check" type="radio" name="radio" [(ngModel)]="period"
                    [value]="1" (ngModelChange)="changePeriod($event)" id="1" />
                <label class="btn btn-secondary" for="1">ΧΕΙΜΕΡΙΝΟΥ<br>ΕΞΑΜΗΝΟΥ</label>
            </li>
            <li class="nav-item" role="presentation">
                <input class="btn-check" type="radio" name="radio" [(ngModel)]="period"
                    [value]="2" (ngModelChange)="changePeriod($event)" id="2" />
                <label class="btn btn-secondary" for="2">ΕΑΡΙΝΟΥ<br>ΕΞΑΜΗΝΟΥ</label>
            </li>
        </ul>
        <div class="ui_title--primary ui_title--primary_specific">Δηλώσεις Μαθημάτων ανά Πρόγραμμα Σπουδών</div>
        <div class="tab-content" id="myTabContent0">
            <table class="ui_table responsive">
                <thead>
                    <tr>
                        <th>Πρόγραμμα σπουδών</th>
                        <th>Σε εξέλιξη</th>
                        <th>Ολοκληρωμένες με επιτυχία</th>
                        <th>Ολοκληρωμένες με σφάλματα</th>
                        <th>Απορριφθείσες / Κλειστές</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let regStat of regStats">
                        <td>{{ regStat.name }}</td>
                        <td>{{ regStat.inProgress }}</td>
                        <td>{{ regStat.completed_succeed }}</td>
                        <td>{{ regStat.completed_failed }}</td>
                        <td>{{ regStat.closed }}</td>
                    </tr>
                <tbody>
            </table>
        </div>
    </div>
</div>