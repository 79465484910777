import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ChartConfiguration, ChartData, ChartOptions, ChartType } from "chart.js";
import { Router } from '@angular/router';
import { DateTime } from 'luxon';


@Component({
  selector: 'app-course-regs-per-dept-per-day',
  templateUrl: './course-regs-per-dept-per-day.component.html',
  styleUrls: ['./course-regs-per-dept-per-day.component.scss']
})
export class CourseRegsPerDeptPerDayComponent implements OnInit, OnChanges {

  hoursData: number[] = [];
  datasetsB: any[] = []
  departments: string[] = [];

  //dataRLoaded = false // Είναι μια μεταβλητή ελέγχου ώστε να ελένξουμε αν έχουν φορτωθεί όλα τα δεδομένα πριν φορτώσουμε τη γραφική 
  public lineChartLegend = true;

  constructor(private _route: ActivatedRoute, private _router: Router) {
  }

  // φορτώνουμε την ημερομηνία date από το url που κάλεσε το component CourseDeptRegsPerDayComponent
  date = this._route.snapshot.params.date;

  // φορτώνουμε το department από το url που κάλεσε το component CourseDeptRegsPerDayComponent
  department = this._route.snapshot.params.dept;

  // Μεταβλητή title τύπου string για τον τίτλο της γραφικής
  title: string = "";

  // Μεταβλητή lineChartLabels τύπου array string, όπου θα έχει μέσα τα labels του άξονα x της γραφικής 
  public lineChartLabels: string[] = ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00", "13:00",
    "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];

  // Μεταβλητή regsStatsPerDayData τύπου ChartData και είδος line όπου φορτώνουμε τα δεδομένα της γραφικής
  regsStatsPerDayData: ChartData<'line'> = {
    datasets: [], labels: []
  };

  /* Μεταβλητή lineChartOptions τύπου ChartOptions και είδος line όπου φορτώνουμε διάφορες επιλογές για 
  τη γραφική, οπως ο τίτλος, ο υποτιτλος, ο τίτλος του άξονα x, ο τίτλος του άξονα y και άλλα της γραφικής */
  public lineChartOptions: ChartOptions<'line'> = {};

  ngOnInit(): void {
    this.getCourceRegsPerDeptPerDay(this.department, this.date);
  }

  ngOnChanges(): void {
    this.regsStatsPerDayData = {
      datasets: [], labels: []
    };
    this.getCourceRegsPerDeptPerDay(this.department, this.date);
  }

  public prevDay(): void {
    this.date = DateTime.fromISO(this.date).minus({ days: 1 }).toFormat('yyyy-MM-dd');
    //console.log(this.date);
    this.ngOnChanges();
    this._router.navigate(["/course-regs-per-dept-per-day/" + this.department + "/" + this.date]);
  }

  public nextDay(): void {
    var next_day = DateTime.fromISO(this.date).plus({ days: 1 }).toFormat('yyyy-MM-dd');
    //console.log(this.date);
    // κάνουμε έναν έλεγχο ώστε να μην μπορεί να δείξει ημερομηνίες που είναι μελλοντικές.
    if (next_day <= DateTime.now().toFormat('yyyy-MM-dd')) {
      this.date = next_day;
      this.ngOnChanges();
      this._router.navigate(["/course-regs-per-dept-per-day/" + this.department + "/" + this.date]);
    }
  }

  /* Συνάρτηση που φορτώνει τα αποτελέσματα JSON από το link https://ws-ext.it.auth.gr/open/getCourceDeptsRegsPerDay/' + schoolYear + '/' + period στις μεταβλητές onDate, students, registrations, ενώ αντίστοιχα 
  τα φορτώνει και στη γραφική */
  async getCourceRegsPerDeptPerDay(dept: string, date: string) {

    (document.getElementById("daySelected") as HTMLElement).textContent = date;

    let url = 'https://ws-ext.it.auth.gr/open/getCourseRegsPerDeptPerDay/' + dept + '/' + date;
    let jsonResults = await (await fetch(url)).json();
    if (!Array.isArray(jsonResults && jsonResults.regsstats)) { return Promise.resolve() };
    // Φορτωνουμε τα αποτελέσματα JSON στις τοπικές μεταβλητές onDate, students και registrations

    this.datasetsB = [];

    this.hoursData = [];
    dept = jsonResults.regsstats[0]?.department ?? "not found";


    var k = 0;
    while (k <= 23) {
      this.hoursData.push(jsonResults.regsstats[0]?.[k]);
      k++;
    }

    var newDataset: any = [];
    //console.log(countDept);
    //var i = countDept - 1; // 
    // while (i >= 0) {
    //console.log(dept + " " + this.hoursData);
    if (dept == "not found") {
      dept="Δεν υπάρχουν δηλώσεις.";
      var selectColour = "#fff";
    } else {
      var selectColour = "#" + Math.floor(Math.random() * 16777215).toString(16);
    }
    newDataset = {
      label: dept,
      data: this.hoursData,
      backgroundColor: selectColour,
      borderColor: selectColour,
      pointBackgroundColor: selectColour,
      pointBorderColor: selectColour
    }


    this.datasetsB.push(newDataset);

    this.regsStatsPerDayData = {
      labels: this.lineChartLabels,
      datasets: this.datasetsB,
    }

    this.title = 'Εξέλιξη Δηλώσεων Μαθημάτων ανά Ώρα για ' + date; // Ορίζουμε τον νέο τίτλο της γραφικής με βάση τη χρονιά 
    // Φορτώνουμε το νέο τίτλο στη γραφική
    this.lineChartOptions = {
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            precision: 0
          }
        }
      },
      plugins: {
        title: {
          display: true,
          text: this.title,
          font: {
            size: 20
          }
        }
      }
    };
  }
}