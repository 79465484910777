<div class="ui_content" id="ui_content_specific">
    <div class="ui_content__active_area"><div style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
    ">
        <button (click)="prevYear()" class="fa-solid fa-chevron-left selectButton"></button>
        <select [(ngModel)]="year" (ngModelChange)="changeYear($event)" class="yearSelector">
            <option *ngFor="let year of years" [value]="year">{{ year - 1 }} - {{ year }}</option>
        </select>
        <button (click)="nextYear()" class="fa-solid fa-chevron-right selectButton"></button></div>
        <ul class="nav nav-tabs mb-3" id="myTab0" role="tablist">
            <li class="nav-item" role="presentation">
                <input mdbRadio class="btn-check" type="radio" [checked]="true" name="radio" [(ngModel)]="period"
                    [value]="1" (ngModelChange)="changePeriod($event)" id="1" />
                <label class="btn btn-secondary" for="1">ΧΕΙΜΕΡΙΝΟΥ<br>ΕΞΑΜΗΝΟΥ</label>
            </li>
            <li class="nav-item" role="presentation">
                <input mdbRadio class="btn-check" type="radio" name="radio" [(ngModel)]="period"
                    [value]="2" (ngModelChange)="changePeriod($event)" id="2" />
                <label class="btn btn-secondary" for="2">ΕΑΡΙΝΟΥ<br>ΕΞΑΜΗΝΟΥ</label>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent0">
            <div class="tab-pane fade show active" id="chartTab" role="tabpanel" aria-labelledby="chart-tab0">
                <canvas *ngIf="dataRLoaded" baseChart  min-height="500" max-height="500" style="width: 100vw; height: 100vh;max-height:500px;" [type]="'line'" [data]="regsStatsPerDayData"
                    [options]="lineChartOptions" [legend]="lineChartLegend" (chartClick)="chartClicked($event)">
                </canvas>
            </div>
        </div>
    </div>
</div>