import { AfterViewInit, Component, ViewChild , OnInit } from '@angular/core';
import { MdbDropdownDirective } from 'mdb-angular-ui-kit/dropdown';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit, AfterViewInit {
  /* Ήταν με ! αντί για ? και εμφάνιζε ο browser error "TypeError: Cannot read properties of undefined 
  (reading 'show')", οπότε προσθέσαμε το ?, ώστε να μην μας αναφέρει undefined */
  @ViewChild('dropdown') dropdown?: MdbDropdownDirective; 
  
  constructor() { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // προσθέσαμε και εδώ το ? για να μην μας αναφέρει undefined ο browser 
      this.dropdown?.show();
    }, 0);
  }
  
  title = 'sis-angular';

  ngOnInit(): void {
  }

  setLang(lang: any) {
    if (lang == 'en') {
      (document.getElementById('ui_header__title--partC') as HTMLElement).textContent = "IT Center AUTh";
      //(document.getElementById('ui_title--primary') as HTMLElement).textContent="AUTh eUniversity Services"
      (document.getElementById('tempblock_msg') as HTMLElement).textContent = "You have requested too many webpages in the last few minutes. Please allow time for each webpage to load fully, before requesting a new one.";
      (document.getElementById('ui_banner__box--login_button_text_specific') as HTMLElement).textContent = "Login";
      (document.getElementById('main_text') as HTMLElement).textContent = "Student Information System services require the use of the University ID. \
      Students can: \
      <ul> \
      <li>access classΒ and grade information</li> \
      <li>electronically enroll to classes (not activated for all departments)</li> \
      </ul> \
      Teaching staff can: \
      <ul> \
      <li>access course information, class and grade lists</li> \
      <li>electronically submit student grades making use of a hardware crypto token device (not activated for all departments)</li> \
      </ul>";
      (document.getElementById('ui_header__title--partC') as HTMLElement).textContent = "IT Center AUTh";
      //(document.getElementById('ui_footer') as HTMLElement).textContent="IT Center AUTh";
      //$(".ui_footer").html('<a href="http://it.auth.gr">IT Center</a> - Aristotle University of Thessaloniki    |   <a href="mailto:support@auth.gr">support@auth.gr</a>- 2310999000     ');
    }
    else {
      (document.getElementById('ui_header__title--partC') as HTMLElement).textContent = "Κέντρο Ηλεκτρονικής Διακυβέρνησης ΑΠΘ";
      //(document.getElementById('ui_title--primary') as HTMLElement).textContent="AUTh eUniversity Services"
      (document.getElementById('ui_banner__box--login_button_text_specific') as HTMLElement).textContent = "Είσοδος";
      (document.getElementById('main_text') as HTMLElement).textContent = "Η είσοδος στις υπηρεσίες Ηλεκτρονικής Γραμματείας γίνεται με χρήση του Ιδρυματικού Λογαριασμού ΑΠΘ. Οι δυνατότητες που προσφέρονται σε φοιτητές είναι: \
      <ul> \
      <li>να βλέπουν τα μαθήματα και τους βαθμούς τους</li> \
      <li>να υποβάλλουν ηλεκτρονικά τη δήλωση μαθημάτων τους</li> \
      <li>να διεκπεραιώνουν ηλεκτρονικά αιτήσεις για την έκδοση πιστοποιητικών</li> \
      </ul> \
      Οι δυνατότητες που προσφέρονται σε διδάσκοντες είναι: \
      <ul> \
      <li>να βλέπουν τα μαθήματα, τις τάξεις, τους φοιτητές και τους βαθμούς των φοιτητών τους</li> \
      <li>να υποβάλλουν ηλεκτρονικά τα βαθμολόγια τους με χρήση ειδικής κρυπτογραφικής συσκευής</li> \
      </ul>\
      Oι δυνατότητες που προσφέρονται στους απόφοιτους είναι: \
      <ul> \
      <li>να διεκπεραιώνουν ηλεκτρονικά αιτήσεις για την έκδοση πιστοποιητικών</li> \
      </ul>";
      (document.getElementById('error_msg-1') as HTMLElement).textContent = "It seems that your Department does not support Electronic Registrar Services. For more information please contact your Departmentβs Registrar Office.";
      (document.getElementById('error_msg-2') as HTMLElement).textContent = "You are not authorised to use the system because your account does not belong to student or faculty.";
      (document.getElementById('error_msg-down') as HTMLElement).textContent = "System is temporally out of order. Maintenance operations are taking place";
      (document.getElementById('error_msg-overload') as HTMLElement).textContent = "The system is under heavy load. Please try later.";
      (document.getElementById('ui_header__title--partC') as HTMLElement).textContent = "IT Center AUTh";
      //(document.getElementById('ui_footer') as HTMLElement).textContent="IT Center AUTh";
      //$(".ui_footer").html('<a href="http://it.auth.gr">IT Center</a> - Aristotle University of Thessaloniki    |   <a href="mailto:support@auth.gr">support@auth.gr</a>- 2310999000     ');
    }
  }
}