<div id="page">
    <div class="ui_header" id="ui_header_specific">
        <div class="ui_header__active_area">
            <div class="ui_header__part ui_header__sidenav_option" id="ui_header__sidenav_option_specific">
            </div>

            <div class="ui_header__part ui_header__title">
                <a class="ui_header__logo" id="ui_header__logo_specific">
                    <span class="ui_header__title--partA">it</span><span class="ui_header__title--partB">.auth</span>
                    <span class="ui_header__title--seperator"> | </span>
                    <span class="ui_header__title--partC" id="ui_header__title--partC">Κέντρο Ηλεκτρονικής Διακυβέρνησης
                        ΑΠΘ</span>
                    <div style="clear: both;"></div>
                </a>
            </div>

            <div class="ui_header__part ui_header__menu_options" id="ui_header__menu_options_specific">
                <div class="btn" style="vertical-align: middle">
                    <a routerLink="../" style="font-size:x-large;color:white"><div class="fa-sharp fa-solid fa-house"></div></a>
                </div>
                <div mdbDropdown class="btn-group dropstart">
                    <a class="dropdown-toggle" role="button" style="color:white" id="dropdownMenuLink" mdbDropdownToggle aria-expanded="false">
                        <div class="fa-sharp fa-solid fa-chart-column"></div>
                    </a>
                    <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li><a class="dropdown-item" routerLink="../grade-stats-per-day">Ημερήσια Εξέλιξη Κατάθεσης Βαθμολογίων</a></li>
                        
                        <li><a class="dropdown-item" routerLink="../course-regs-per-day">Ημερήσια Εξέλιξη Δηλώσεων Μαθημάτων</a></li>
                        
                        <li><a class="dropdown-item" routerLink="../course-depts-regs">Δηλώσεις Μαθημάτων ανά Πρόγραμμα Σπουδών</a></li>

                        <!-- <li><a class="dropdown-item" routerLink="../new-component">New Component</a></li>
                        
                        <li><a class="dropdown-item"
                                href="https://sis.auth.gr/stats/index.php?type=studentRegs">Εγγραφές Φοιτητών</a></li>
                        <li><a class="dropdown-item" href="https://sis.auth.gr/stats/index.php?type=stud">Στατιστικά
                                Φοιτητολογίου</a></li>
                        <li><a class="dropdown-item"
                                href="https://sis.auth.gr/stats/index.php?type=studentRequests">Εκκρεμή και Ολοκληρωμένα
                                Αιτήματα Φοιτητών ανά Τμήμα</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<router-outlet></router-outlet>

<div class="ui_footer ui_text--size_S">
    <a href="http://it.auth.gr/">Κέντρο Ηλεκτρονικής Διακυβέρνησης</a> - Αριστοτέλειο Πανεπιστήμιο Θεσσαλονίκης
    &nbsp;&nbsp; | &nbsp;&nbsp;
    <a href="mailto:support@auth.gr">support@auth.gr</a> - 2310999000
</div>