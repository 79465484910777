import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';



// Ένα interface ΙRegPeriod με μεταβλητές τα πεδία που έχει ο πίνακας στη βάση.
interface ΙRegPeriod {
  name?: string;
  depcatID?: string;
  start_date?: string;
  end_date?: string;
  reg_year?: string;
  reg_term?: string;
}

interface IAlert {
  show?: boolean;
  message?: string;
  style?: string;
  link?: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})


export class HomeComponent implements OnInit {

  constructor(private router: Router) { }

  regPeriodsUndergraduate: ΙRegPeriod[] = [];
  regPeriodsMaster: ΙRegPeriod[] = [];
  alerts: IAlert[] = [];
  info: string = "info";

  ngOnInit() {
    /* Με το fetch φορτώνουμε τα δεδομένα δυναμικά από το αρχείο regPeriodsUndergraduate.json στη μεταβλητή regPeriodsUndergraduate, όπου περιέχονται οι ημερομηνίες με τις δηλώσεις 
    των προπτυχιακών μαθημάτων */
    fetch('./assets/regPeriodsUndergraduate.json').then((response) => response.json()).then((data) => { if(data.regperiods.length>0){console.log(data.regperiods.length);this.regPeriodsUndergraduate = data.regperiods}else{this.regPeriodsUndergraduate=null}});
  
    /* Με το fetch φορτώνουμε τα δεδομένα δυναμικά από το αρχείο regPeriodsMaster.json στη μεταβλητή regPeriodsMaster, όπου περιέχονται οι ημερομηνίες με τις δηλώσεις 
    των μεταπτυχιακών μαθημάτων */
    fetch('./assets/regPeriodsMaster.json').then((response) => response.json()).then((data) => { if(data.regperiods.length>0){console.log(data.regperiods.length);this.regPeriodsMaster = data.regperiods}else{this.regPeriodsMaster=null}});
    
    /* Με το fetch φορτώνουμε τα δεδομένα δυναμικά από το αρχείο alerts.json στη μεταβλητή alerts, όπου περιέχονται διάφορα μηνύματα/ειδοποιήσεις που
    θέλουμε να εμφανίζονται στην αρχική του sis.auth.gr */
    fetch('./assets/alerts.json').then((response) => response.json()).then((data) => {this.alerts = data.alerts});
  }

  public chartIconClick(dept?: string): void {
    var date = DateTime.now().toFormat('yyyy-MM-dd');
    this.router.navigate(["/course-regs-per-dept-per-day/" + dept + "/" + date]);// "/course-dept-regs-per-day/bio/2023-02-12" 
  }

  /* Χρωματίζει την κάθε γραμμή του πίνακα με βάση το startDate, αν έχει περάσει η ημερομηνία από τη σημερινή επιστρέφει το χρώμα '#E2EFDA' και αν είναι η σημερινή ημερομηνία
  που άνοιξε η δήλωση τότε επιστρέφει το χρώμα '#bfffc6' */
  getColor(startDate: any): string {
    // startDate είναι της μορφής 12/02/23 09:00
    var start_date = DateTime.fromFormat(startDate, "dd/LL/yy HH:mm");
    if (start_date < DateTime.now()) {
      if (DateTime.now().day == start_date.day && DateTime.now().month == start_date.month) { return '#bfffc6' }
      return '#E2EFDA'
    }
    return '';
  }
}

